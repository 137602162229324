<template>
  <div style="position: relative; padding: 0.5rem 1rem">
    <q-icon
      size="30px"
      style="
        position: absolute;
        top: 50%;
        left: 50px;
        transform: translate(0, -50%);
      "
      name="search"
    />
    <input
      style="
        padding: 0.8rem 80px 0.8rem 80px;
        width: 100%;
        color: #898989;
        background-color: #f6f7fa;
        border-radius: 25px;
        outline: none;
        border: none;
      "
      :placeholder="$t('common.item_30')"
      v-model="value"
      @input="search"
    />
  </div>
  <div
    class="row"
    style="padding: 1rem; background-color: #f6f7fa"
    v-if="!value && localArr && localArr.length"
  >
    <div
      class="col-4"
      style="text-align: center"
      v-for="(row, index) in localArr"
      :key="index"
    >
      <div
        style="
          margin: 0 1rem;
          padding: 0.5rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          background-color: #fff;
          border-radius: 5px;
          border: 1px solid #e9e9e9;
        "
        @click="setCountry(row)"
      >
        {{ switchlang(row) }}
      </div>
    </div>
  </div>

  <van-index-bar v-if="!value" :index-list="chars">
    <div v-for="(rs, key, index) in list" :key="index">
      <van-index-anchor :is-scroll="true" :index="key" />
      <van-cell
        :is-scroll="true"
        v-for="(row, i) in rs"
        :key="i"
        :title="switchlang(row)"
        @click="setCountry(row)"
      />
    </div>
  </van-index-bar>
  <div style="padding: 0 1rem" v-else>
    <p
      style="margin: 0; padding: 0.8rem 0; border-bottom: 1px solid #e9e9e9"
      v-for="(row, index) in rs"
      :key="index"
      @click="setCountry(row)"
    >
      {{ switchlang(row) }}
    </p>
  </div>
</template>

<style lang="less"></style>

<script>
import pinyin from "js-pinyin";
export default {
  data() {
    return {
      httpProcess: false,

      value: "",
      rs: [],

      chars: [],
      list: {},

      localArr: [],
    };
  },
  created() {
    pinyin.setOptions({ checkPolyphone: false, charCase: 0 });
    var str = window.sessionStorage["country"];
    if (str && typeof str === "string") {
      var o = JSON.parse(str);
      if (typeof o === "object") {
        this.localArr = o;
      }
    }
    this.getData();
  },
  methods: {
    switchlang(obj) {
      switch (this.$i18n.locale) {
        case "zh":
          return obj.zh_name;
        case "en":
        default:
          return obj.en_name;
      }
    },
    setCountry(obj) {
      var self = this;
      if (self.httpProcess) return;
      self.httpProcess = true;
      self
        .$axios({
          method: "post",
          url: "/api/set/country",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          params: { country: obj.code },
        })
        .then(() => {
          self.httpProcess = false;
          self.setLocalArr(obj);
          self.$router.push({ path: "/", query: { mark: "imei" } });
        })
        .catch(function (error) {
          self.httpProcess = false;
          var msg;
          switch (error.response.data.code) {
            case 401:
              msg = self.$t("common.item_01");
              break;
            default:
              msg = self.$t("common.item_02");
          }
          self.$q.dialog({
            message: msg,
            ok: self.$t("common.item_03"),
          });
        });
    },
    getData() {
      var self = this;
      self
        .$axios({
          method: "post",
          url: "/api/query/all/country",
        })
        .then((res) => {
          self.$nextTick(() => {
            if (res.data.list && res.data.list.length) {
              let rs = self.format(res.data.list);
              self.chars = rs.chars;
              self.list = rs.list;
            }
          });
        })
        .catch(function (error) {
          self.httpProcess = false;
          var msg;
          switch (error.response.data.code) {
            case 401:
              msg = self.$t("common.item_01");
              break;
            default:
              msg = self.$t("common.item_02");
          }
          self.$q.dialog({
            message: msg,
            ok: self.$t("common.item_03"),
          });
        });
    },
    format(arr) {
      var obj = {};
      var chars = [];
      for (var i = 0; i < arr.length; i++) {
        var item = {};
        item.id = arr[i].id;
        item.code = arr[i].code;
        item.en_name = arr[i].en_name;
        item.zh_name = arr[i].zh_name;

        var char;
        switch (this.$i18n.locale) {
          case "zh":
            char = pinyin.getFullChars(arr[i].zh_name).substr(0, 1);
            break;
          case "en":
          default:
            char = arr[i].en_name.substr(0, 1).toUpperCase();
        }

        if (!obj[char]) {
          obj[char] = [];
          chars.push(char);
        }
        obj[char].push(item);
      }
      chars.sort();

      const newObj = {};
      Object.keys(obj)
        .sort()
        .forEach(function (key) {
          newObj[key] = obj[key];
        });

      var end = {};
      end.chars = chars;
      end.list = newObj;
      return end;
    },
    search() {
      if (!this.value) return;

      var rs = [];
      for (var key in this.list) {
        var arr = this.list[key];
        for (var i = 0; i < arr.length; i++) {
          var v;
          switch (this.$i18n.locale) {
            case "zh":
              v = arr[i].zh_name;
              break;
            case "en":
            default:
              v = arr[i].en_name;
          }
          if (v.search(this.value) != -1) {
            rs.push(arr[i]);
          }
        }
      }
      this.rs = rs;
    },
    setLocalArr(obj) {
      var item = {};
      item.code = obj.code;
      item.zh_name = obj.zh_name;
      item.en_name = obj.en_name;
      var arr = [];
      arr.push(item);

      var str = window.sessionStorage["country"];
      if (str && typeof str === "string") {
        var o = JSON.parse(str);
        if (typeof o === "object") {
          for (var i = 0; i < o.length; i++) {
            if (arr.length > 2) break;
            if (o[i].code === arr[0].code) continue;
            arr.push(o[i]);
          }
        }
      }

      window.sessionStorage["country"] = JSON.stringify(arr);
    },
  },
};
</script>
